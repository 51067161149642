import React from "react";

import BannerTop from "@/components/About/BannerTop";
import Words from "@/components/About/Words";
import Achievements from "@/components/About/Achievements";
import GetInTouch from "@/components/Home/GetInTouch";
import Subscription from "@/components/Home/Subscription";

const About = () => {
  return (
    <>
      <BannerTop />
      <Words />
      <Achievements/>
      <GetInTouch/>
      <Subscription/>
    </>
  );
};

export default About;
