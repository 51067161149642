import React, { FC, useEffect, useState } from "react";

export const Counter: FC<CounterProps> = ({
  value,
  delay,
  containerClass,
  step = 1,
}) => {
  const [counter, setCounter] = useState(0);

  const counterIncrement = (): void => {
    if (counter < value) {
      setCounter(counter + step);
    } else if (counter > value) {
      setCounter(value);
    }
  };

  useEffect(() => {
    const counterTimeout: NodeJS.Timeout = setTimeout(counterIncrement, delay);
    return () => {
      clearTimeout(counterTimeout);
    };
  }, [counter]);

  return <span className={containerClass}>{counter}</span>;
};

type CounterProps = {
  value: number;
  delay: number;
  step: number;
  containerClass?: string;
};
