import React from "react";
import Image from "@/components/Image";
import { Counter } from "@/lib/counter";
const Words = () => {
  return (
    <div className="total-about-page">
      <div className="container">
        <div className="row row-cols-1 row-cols-md-2">
          <div className="col">
            <figure className="oneer-pic-div">
              <Image name="owner.png" />
            </figure>
            <div className="onwer-div">
              <h3>CEO Madelin Marichal</h3>
              <p>Founder</p>
            </div>
          </div>

          <div className="col">
            <div className="comon-about-div-sec">
              <h5>
                <span className="d-block mb-2">
                  <img src="images/heading-t.png" alt="picv" />{" "}
                </span>
                About
              </h5>
              <h1>Start Professional Care & Support LLC</h1>
              <p>
                Was founded on February 19, 2018 with the objective of
                delivering high quality care, helping and supporting the
                community and clients with special needs in all their daily
                activities.
              </p>
              <p>
                Our mission is to positively impact the community, to ensure the
                best possible care and assistance through qualified/ trained
                professionals, improving the lives of individuals from a very
                young age to elderly.
              </p>
              <p>
                Provide services to elderly or clients with but not limited to
                Intellectual or Developmental Disabilities, severe forms of
                autisms, Cerebral Palsy, Spina bifida cystica or
                myelomeningocele, Prader-Willi syndrome, Down syndrome,
                Phelan-McDermid syndrome.
              </p>
              <div className="row row-cols-1 row-cols-md-2 g-0">
                <div className="col">
                  <div className="our-text">
                    <h2>
                      {" "}
                      <Counter
                        containerClass="counter"
                        step={1.0}
                        delay={60}
                        value={4.5}
                      />
                      k +
                    </h2>
                    <p>Happy patient</p>
                  </div>
                </div>
                <div className="col">
                  <div className="our-text">
                    <h2>
                      {" "}
                      <Counter
                        containerClass="counter"
                        step={1.0}
                        delay={60}
                        value={4.8}
                      />
                      k +
                    </h2>

                    <p>Pleased relatives</p>
                  </div>
                </div>
                <div className="col">
                  <div className="our-text">
                    <h2>
                      {" "}
                      <Counter
                        containerClass="counter"
                        step={5}
                        delay={60}
                        value={60}
                      />{" "}
                      +
                    </h2>

                    <p>Trained professionals</p>
                  </div>
                </div>
                <div className="col">
                  <div className="our-text">
                    <h2>
                      {" "}
                      <Counter
                        containerClass="counter"
                        step={1}
                        delay={60}
                        value={10}
                      />{" "}
                      +
                    </h2>

                    <p>Worldwide Branch</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Words;
