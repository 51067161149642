import React from "react";
import Image from "@/components/Image";

const Achievements = () => {
  return (
    <section className="sec-d1 mt-0 pt-5 d-block actchive-team">
      <div className="container">
        <h1 className="text-center">
          <span className="d-block">
            <img src="images/heading-t.png" alt="picv" />
          </span>
          <span> Overview </span> Our Achievements
        </h1>
        <div className="row g-0 row-cols-1 row-cols-md-2 mt-3">
          <div className="col">
            <div className="comon-us">
              <figure>
                <Image name="best-seller.png" />
              </figure>
              <h5>
                The Best Medical <span className="d-block"> Center 2019 </span>
              </h5>
            </div>
          </div>

          <div className="col">
            <div className="comon-us">
              <figure>
                <Image name="private-nursing-icon.png" />
              </figure>
              <h5>
                The Best
                <span className="d-block"> Nurses Award </span>
              </h5>
            </div>
          </div>

          <div className="col">
            <div className="comon-us">
              <figure>
                <Image name="certificate.png" />
              </figure>
              <h5>
                Certified by the
                <span className="d-block"> International Association </span>
              </h5>
            </div>
          </div>

          <div className="col">
            <div className="comon-us">
              <figure>
                <Image name="medal.png" />
              </figure>
              <h5>
                The Five <span className="d-block"> Star Award </span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Achievements;
