import React from "react";
import Layout from "@/components/layout";
import Header from "@/components/Header";
import About from "@/components/About";
import Footer from "@/components/Footer";
import SEO from "@/components/seo";
import { useLocation } from "@reach/router";
const Ab = () => {
  const { host } = useLocation();
  const canonical = `${host}/about`;
  return (
    <Layout>
      <SEO title="About" canonical={canonical} />
      <Header />
      <About />
      <Footer />
    </Layout>
  );
};

export default Ab;
